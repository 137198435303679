import { gEditor, gEditorOptions } from "./typings/editor";
import { noop } from "./utils/noop";
import * as FE from "./vendor/froala_editor";
import { openGeogebraEvaluator } from "./vendor/froala_editor/plugins/geogebra/openGeogebraEvaulator";

export function createNativeEditor(
  selectorElem: HTMLElement | null,
  options: gEditorOptions | undefined
): gEditor {
  const elem = selectorElem ?? document.createElement("div");

  // Extract initial value from the element prior to creating custom
  // Mobile-friendly editor
  const initialValue = isInputElement(elem) ? elem.value : elem.innerHTML;

  // if the element is a textarea, then create a custom container that we can
  // inject the editor capabilities into
  let container: HTMLElement = elem;

  if (isInputElement(container)) {
    container = document.createElement("div");
    container.className = elem.className;

    elem.parentNode?.insertBefore(container, elem);
    elem.style.display = "none";
  } else {
    container.innerHTML = "";
  }

  const placeholder =
    options?.placeholderText ??
    // @ts-expect-error: We're missing official typings - the following is
    // completely correct, but TypeScript throws because it doesn't understand the
    // actual typings
    FE.LANGUAGE["da"].translation["Type something"];

  // Inject layer based on Froala styling inside the editor, so we can
  // use a similarly styled barebones mobile editor
  const wrapper = document.createElement("div");
  const editor = document.createElement("div");

  wrapper.appendChild(editor);
  container.appendChild(wrapper);

  container.classList.add("fr-box");
  container.classList.add("fr-basic");
  wrapper.className = "fr-wrapper";
  editor.className = "fr-view fr-element gEditor-isMobile";

  // Configure the editor layer to use mobile OS built-in content
  // editable
  editor.setAttribute("contentEditable", "true");
  editor.setAttribute("placeholder", placeholder);

  // variable used to retain saved selection
  let prevSelectionRange: Range | undefined;

  // create an editor context
  const editorCtx: gEditor = {
    el: editor,
    html: {
      get(): string {
        return editor.innerHTML;
      },
      set(html: string) {
        editor.innerHTML = html;
      },
      insert(html: string) {
        // replace current selection with the
        const prevSelection = window.getSelection();

        if (!prevSelection?.rangeCount) {
          return;
        }

        const selectionRange = prevSelection.getRangeAt(0);

        if (!selectionRange) {
          // bail out if no selection exists
          return;
        }

        if (
          selectionRange.commonAncestorContainer !== editor &&
          !editor.contains(selectionRange.commonAncestorContainer)
        ) {
          // bail out if selection is not currently contained within the editor
          return;
        }

        // ... otherwise replace the current selection with the newly provided
        // html
        selectionRange.deleteContents();
        selectionRange
          .createContextualFragment(html)
          .childNodes.forEach((node) => {
            selectionRange?.insertNode(node);
          });

        // ... and move the selection to the end of the modified content
        window
          .getSelection()
          ?.setPosition(selectionRange.endContainer, selectionRange.endOffset);
      },
    },
    image: {
      get: () => undefined,
      repositionResizer: noop,
    },
    events: {
      focus() {
        editor.focus();
      },
      on(eventName: string, listener: () => void) {
        editor.addEventListener(eventName, listener);
      },
      trigger(eventName: string) {
        editor.dispatchEvent(new CustomEvent(eventName));
      },
    },
    edit: {
      off(): void {
        editor.contentEditable = "false";
      },
      on(): void {
        editor.contentEditable = "true";
      },
    },
    toolbar: {
      show: noop,
      hide: noop,
    },
    selection: {
      clear() {
        window.getSelection()?.collapseToStart();
        editor.blur();
      },

      setAfter(node) {
        const parentElem = node.parentElement;
        const nodeIndex = Array.from(parentElem?.childNodes ?? []).findIndex(
          (it) => it === node
        );

        console.log(parentElem, nodeIndex);

        if (!parentElem || nodeIndex === -1) {
          return;
        }

        window.getSelection()?.collapse(parentElem, nodeIndex + 1);
      },

      blocks() {
        return [];
      },

      element() {
        return elem;
      },

      endElement() {
        return elem;
      },

      text() {
        return window.getSelection()?.toString() ?? "";
      },

      save() {
        const selection = window.getSelection();

        prevSelectionRange = selection?.rangeCount
          ? selection.getRangeAt(0).cloneRange()
          : undefined;
      },
      restore() {
        window.getSelection()?.removeAllRanges();

        if (prevSelectionRange) {
          window.getSelection()?.addRange(prevSelectionRange);
        }
      },
    },
    format: {
      is() {
        return false;
      },
      removeStyle: noop,
      applyStyle: noop,
    },
    commands: {
      bold: noop,
      italic: noop,
      underline: noop,
      superscript: noop,
      subscript: noop,
    },
    lists: {
      format: noop,
    },
    paragraphFormat: {
      apply: noop,
    },
    placeholder: {
      refresh: noop,
    },
    fontSize: {
      apply: noop,
    },
    geogebra: {
      open: () => {
        openGeogebraEvaluator(editorCtx);
      },
    },
    link: {
      get() {
        return undefined;
      },
      insert: noop,
    },
    destroy: noop,
  };

  // bind events
  if (options?.events?.blur) {
    editor.addEventListener("blur", options.events.blur.bind(editorCtx));
  }
  if (options?.events?.focus) {
    editor.addEventListener("focus", options.events.focus.bind(editorCtx));
  }
  if (options?.events?.keydown) {
    editor.addEventListener("keydown", options.events.keydown.bind(editorCtx));
  }
  if (options?.events?.keypress) {
    editor.addEventListener(
      "keypress",
      options.events.keypress.bind(editorCtx)
    );
  }
  if (options?.events?.keyup) {
    editor.addEventListener("keyup", options.events.keyup.bind(editorCtx));
  }
  if (options?.events?.input) {
    editor.addEventListener("input", options.events.input.bind(editorCtx));
  }
  if (options?.events?.contentChanged) {
    editor.addEventListener(
      "change",
      options.events.contentChanged.bind(editorCtx)
    );
  }

  // bind event delegate to handle double-clicking on geogebra images, so that
  // the editor can be re-opened
  editor.addEventListener("click", (evt) => {
    const evtTarget = evt.target as HTMLElement;

    if (evtTarget.matches("img.LaTeX-formula")) {
      const parentElem = evtTarget.parentElement;
      const evtTargetIndex = Array.from(parentElem?.childNodes ?? []).findIndex(
        (it) => it === evtTarget
      );

      if (parentElem && evtTargetIndex !== -1) {
        window.getSelection()?.setPosition(parentElem, evtTargetIndex + 1);
      }
    }
  });

  editor.addEventListener("dblclick", (evt) => {
    const evtTarget = evt.target as HTMLElement;

    if (evtTarget.matches("img.LaTeX-formula")) {
      openGeogebraEvaluator(editorCtx, evtTarget as HTMLImageElement);
    }
  });

  editor.style.outline = "none";

  // Re-inject initial value into the newly created editor layer
  editor.innerHTML = initialValue;

  // Return mock-up of the API exposed by the FroalaEditor
  return editorCtx;
}

function isInputElement(
  elem: HTMLElement
): elem is HTMLTextAreaElement | HTMLInputElement {
  switch (elem.tagName.toLowerCase()) {
    case "textarea":
    case "input":
      return true;
  }

  return false;
}

import { css } from "linaria";

export const defaultThemeCls = css`
  --gfr-font: "proxima-nova", "franklin-gothic-urw", "droid-sans", helvetica,
    arial, sans-serif;

  --gfr-highlight-color: 220, 67, 32;
  --gfr-black-color: 56, 56, 56;
  --gfr-separator-color: 204, 204, 204;
  --gfr-separator-color--dark: 174, 174, 174;

  --easing--in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
`;

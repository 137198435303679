/**
 * if user creates a single line SVG, Geogebra will export it at approximately
 * 35px height; use this value to determine how many lines the exported SVG
 * will approximately consist of
 */
const SVG_BASELINE_HEIGHT = 35;

/**
 * parse the generated SVG image and crop it properly without unwanted
 * whitespace (which might be generated by Geogebra for some unknown reason),
 * and determine how many lines the svg spans over
 */
export function processSvg(
  dataUri: string
): {
  dataUri: string;
  lineCount: number;
  optimalHeight: number;
} {
  const svgDoc = parseSvgDataUri(dataUri);

  // determine the optimal height of the SVG, and then apply it to the document
  // so we avoid unwanted spacing
  const optimalHeight = determineOptimalHeight(svgDoc);
  svgDoc.querySelector("svg")?.setAttribute("height", String(optimalHeight));

  return {
    dataUri: serializeSvgDataUri(svgDoc),
    lineCount: optimalHeight / SVG_BASELINE_HEIGHT,
    optimalHeight,
  };
}

function parseSvgDataUri(dataUri: string): Document {
  const svg = decodeURIComponent(dataUri.split(",")[1] ?? "");
  const parser = new DOMParser();

  return parser.parseFromString(svg, "image/svg+xml");
}

function serializeSvgDataUri(svgDoc: Document): string {
  const dataUri = encodeURIComponent(
    new XMLSerializer().serializeToString(svgDoc)
  );

  return `data:image/svg+xml;utf8,${dataUri}`;
}

function parseHeightFromElement(node: Element | undefined): number | undefined {
  if (!node) {
    return;
  }

  const height = parseFloat(node.getAttribute("height") ?? "");

  return !Number.isNaN(height) ? height : undefined;
}

function determineOptimalHeight(svgDoc: Document): number {
  return Array.from(svgDoc.querySelectorAll("svg, rect")).reduce(
    (acc, node) => {
      const elemHeight = parseHeightFromElement(node);

      // ignore rects with a tiny / undefined height
      if (elemHeight === undefined || elemHeight < 10) {
        return acc;
      }

      // ignore rects that doesn't overlap the entire svg
      if (
        node.tagName.toLowerCase() === "rect" &&
        (node.getAttribute("x") !== "0" || node.getAttribute("y") !== "0")
      ) {
        return acc;
      }

      // ... otherwise identify the smallest meaningful height
      return elemHeight !== undefined ? Math.min(elemHeight, acc) : acc;
    },
    1000
  );
}

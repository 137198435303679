import { css } from "linaria";

export const notesbogThemeCls = css`
  --gfr-font: "proxima-nova", "franklin-gothic-urw", "droid-sans", helvetica,
    arial, sans-serif;

  --gfr-highlight-color: 220, 67, 32;
  --gfr-black-color: 56, 56, 56;
  --gfr-separator-color: 214, 212, 186;
  --gfr-separator-color--dark: 182, 181, 160;

  --easing--in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
`;

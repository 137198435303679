import { gEditor, gEditorOptions } from "./typings/editor";
import FroalaEditor, * as FE from "./vendor/froala_editor";

export function createFroalaEditor(
  selector: string,
  options: gEditorOptions | undefined
): Promise<gEditor> {
  return new Promise((resolve) => {
    // @ts-expect-error: We don't have typings of the Froala editor, but the
    // following is the correct way of initializing it
    const editor = new FroalaEditor(
      selector,
      injectDefaultOptions(options),
      () => {
        resolve(editor);
      }
    );
  });
}

function injectDefaultOptions(
  options: gEditorOptions | undefined
): gEditorOptions {
  // we're lacking official typings of Froala-editor options, so we're using
  // any to disable type checking - that's the best we can do until official
  // typings become available
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allOptions: { [key: string]: any } = Object.assign(
    {
      key: process.env.FROALA_LICENSE_KEY,
      attribution: false,
    },
    options
  );

  if (!("enter" in allOptions)) {
    allOptions.enter = FE.ENTER_BR;
  }

  if (!("imageInsertButtons" in allOptions)) {
    allOptions.imageInsertButtons = ["imageBack", "|", "imageByURL"];
  }

  if (!("imageEditButtons" in allOptions)) {
    allOptions.imageEditButtons = [
      "imageReplace",
      "imageAlign",
      "imageRemove",
      "|",
      "imageLink",
      "linkOpen",
      "linkEdit",
      "linkRemove",
      "-",
      "imageDisplay",
      "imageStyle",
      "imageSize",
      "imageLightbox",
    ];
  }

  if (!("imageUploadRemoteUrls" in allOptions)) {
    allOptions.imageUploadRemoteUrls = false;
  }

  if (!("videoEditButtons" in allOptions)) {
    allOptions.videoEditButtons = [
      "videoRemove",
      "|",
      "videoDisplay",
      "videoAlign",
      "videoSize",
      "|",
      "videoLightbox",
    ];
  }

  if (!("imageDefaultWidth" in allOptions)) {
    allOptions.imageDefaultWidth = "auto";
  }

  if (!("linkStyles" in allOptions)) {
    allOptions.linkStyles = {
      "gfr-link-bold": "Highlighted",
      "gfr-link-black": "Black",
    };
  }
  if (!("imageStyles" in allOptions)) {
    allOptions.imageStyles = {
      "gfr-image-rounded": "Rounded",
      "gfr-image-dimmed": "Dimmed",
    };
  }
  if (!("tableCellStyles" in allOptions)) {
    allOptions.tableCellStyles = {
      "gfr-tablecell-highlighted": "Highlighted",
      "gfr-tablecell-dimmed": "Dimmed",
    };
  }

  if (!("fontSize" in allOptions)) {
    allOptions.fontSize = [14, 16, 18, 24];
  }

  if (!("language" in allOptions)) {
    allOptions.language = "da";
  }

  if (!("linkAlwaysBlank" in allOptions)) {
    allOptions.linkAlwaysBlank = true;
  }

  if (!("listAdvancedTypes" in allOptions)) {
    allOptions.listAdvancedTypes = false;
  }

  if (!("spellcheck" in allOptions)) {
    allOptions.spellcheck = false;
  }

  const toolbarButtons = {
    moreMisc: {
      buttons: [
        "paragraphFormat",
        "fontSize",
        "bold",
        "italic",
        "underline",
        "subscript",
        "superscript",
        "formatOL",
        "formatUL",
        "outdent",
        "indent",
        "insertImage",
        "insertLink",
        "insertSkoletube",
        "insertGeogebra",
        "insertTable",
        "textColor",
        "backgroundColor",

        // Toggled when clicking the more button
        "undo",
        "redo",
        "fullscreen",
        "html",
        "help",
      ],
      buttonsVisible: 18,
    },
  };

  if (!("linkInsertButtons" in allOptions)) {
    allOptions.linkInsertButtons = ["linkBack"];
  }

  if (!("linkList" in allOptions)) {
    allOptions.linkList = [];
  }

  if (!("toolbarButtons" in allOptions)) {
    allOptions.toolbarButtons = toolbarButtons;
  }
  if (!("toolbarButtonsXS" in allOptions)) {
    allOptions.toolbarButtonsXS = toolbarButtons;
  }
  if (!("toolbarButtonsSM" in allOptions)) {
    allOptions.toolbarButtonsSM = toolbarButtons;
  }
  if (!("toolbarButtonsMD" in allOptions)) {
    allOptions.toolbarButtonsMD = toolbarButtons;
  }

  if (!("events" in allOptions)) {
    allOptions.events = {};
  }

  if (!("image.inserted" in allOptions)) {
    allOptions.events["image.inserted"] = handleInsecureImageInsert;
  } else {
    const orgCallback = allOptions.events["image.inserted"];

    allOptions.events["image.inserted"] = (
      $img: JQuery<HTMLImageElement>,
      ...args: unknown[]
    ) => {
      handleInsecureImageInsert($img);
      orgCallback($img, ...args);
    };
  }

  return allOptions;
}

function handleInsecureImageInsert($img: JQuery<HTMLImageElement>): void {
  // we're having issues with jQuery typings of .attr("src") thinking that
  // we're changing attributes which allows chaining rather than loading the
  // actual string value of the attribute
  if ($img[0].src.includes("http://")) {
    $img[0].style.display = "none";

    $img[0].onload = $img[0].onerror = $img[0].onabort = () => {
      alert(
        "Det indsatte billede indlæses over en usikker forbindelse.\n" +
          "Prøv igen, og husk adressen skal starte med https://"
      );

      $img.remove();
    };
  }
}

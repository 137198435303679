import { css } from "linaria";

export const backdropCls = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;

  background: rgba(255, 255, 255, 0.9);

  opacity: 0;
  transition: opacity 0.1s linear;

  &.enter {
    opacity: 1;
  }

  &.exit {
    opacity: 0;
  }
`;

export const containerCls = css`
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 260px; /* make room for the keyboard */

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 245px;
  }

  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
`;

export const throbberCls = css`
  position: absolute;
  z-index: 1;
  top: calc(50% - 64px / 2 - 60px / 2);
  left: calc(50% - 64px / 2);

  box-sizing: border-box;
  width: 64px;
  height: 64px;

  border: 2px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;

  animation: spin 0.9s linear infinite;
  transition: opacity 0.1s linear;

  .${backdropCls}.ready & {
    opacity: 0;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export const inputCls = css`
  flex-grow: 1;
  padding: 0 15px;

  overflow-y: hidden;
  overflow-x: auto;

  @media screen and (max-width: 767px) {
    padding: 0 10px;
  }

  opacity: 0;
  transition: opacity 0.1s linear;

  .${backdropCls}.ready & {
    opacity: 1;
  }

  :global(.GeoGebraFrame .TabbedKeyBoard.KeyBoard.detached) {
    z-index: 1000001 !important;
  }
`;

export const toolbarCls = css`
  position: relative;
  z-index: 100;

  flex-basis: 60px;
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;

  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  & > button {
    appearance: none;
    outline: none;
    cursor: pointer;

    background: none;
    border: none;
    border-radius: 0;

    height: 100%;
    padding: 0 30px;

    display: flex;
    align-items: center;

    font-size: 16px;

    transition: background-color 0.1s linear;

    &:hover {
      background-color: #f5f5f5;
    }

    &[data-btn="save"] {
      font-weight: bold;
    }

    & > span {
      /**
       * fix issue with clickablity of buttons on iPad (clicking the text inside
       * the button wouldn't trigger a click event for some reason)
       */
      pointer-events: none;
    }
  }

  & > button:first-child {
    margin-right: auto;
  }
`;

css`
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global() {
    /* Allow us to programatically hide the resizers */
    .gfr-resize-inactive .fr-handler {
      display: none !important;
    }

    /* Customized styling of formulas */
    .fr-view img.LaTeX-formula {
      box-sizing: content-box;
      border: 0;
      width: auto;
      height: auto;
      max-width: 100% !important;
      margin: 0 !important;
      vertical-align: middle !important;
    }
  }
`;

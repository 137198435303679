import * as FE from "froala-editor";

(function ($) {
    FE.DefineIcon("imageLightbox", {PATH: "M20 4.7c0-.4-.3-.7-.7-.7H15c-.4 0-.7.3-.7.7 0 .2.1.3.2.5L16 6.6c.1.1.1.2 0 .2l-2.9 2.9c-.3.3-.3.9 0 1.2s.9.3 1.2 0L17.2 8c.1-.1.2-.1.2 0l1.5 1.5c.1.1.3.2.5.2.1 0 .2 0 .3-.1.1-.1.3-.3.3-.6V4.7zM4.7 20H9c.4 0 .7-.3.7-.7 0-.2-.1-.3-.2-.5L8 17.4c-.1-.1-.1-.2 0-.2l2.9-2.9c.3-.3.3-.9 0-1.2s-.9-.3-1.2 0L6.8 16c-.1.1-.2.1-.2 0l-1.5-1.5c-.3-.3-.7-.3-.9 0-.1.2-.2.3-.2.5v4.3c0 .4.3.7.7.7z"});

    FE.RegisterCommand("imageLightbox", {
        title: "Åbn i lightbox",

        // This command doesn't change anything - simply opens a lightbox
        // overlay - so don't push anything to undo stack
        focus: false,
        undo: false,
        refreshAfterCallback: false,

        callback: function () {
            var img = this.image.get(),
                imgSrc = img.attr('src');

            this.lightbox.open({
                'size': {
                    'width' : img[0].naturalWidth,
                    'height': img[0].naturalHeight
                },

                'title'     : imgSrc.split('/').pop(),
                'contents'  : '<img src="' + imgSrc.replace(/\"/g, '&#34;') +'" alt="'+ String(img.attr('alt') || '').replace(/\"/g, '&#34;') +'" style="max-width:100%;" />'
            });

            this.image.exitEdit(true, true);
        }
    });
}(jQuery));

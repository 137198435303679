import * as FE from "froala-editor";

(function ($) {
    FE.DefineIcon("videoLightbox", {PATH: "M20 4.7c0-.4-.3-.7-.7-.7H15c-.4 0-.7.3-.7.7 0 .2.1.3.2.5L16 6.6c.1.1.1.2 0 .2l-2.9 2.9c-.3.3-.3.9 0 1.2s.9.3 1.2 0L17.2 8c.1-.1.2-.1.2 0l1.5 1.5c.1.1.3.2.5.2.1 0 .2 0 .3-.1.1-.1.3-.3.3-.6V4.7zM4.7 20H9c.4 0 .7-.3.7-.7 0-.2-.1-.3-.2-.5L8 17.4c-.1-.1-.1-.2 0-.2l2.9-2.9c.3-.3.3-.9 0-1.2s-.9-.3-1.2 0L6.8 16c-.1.1-.2.1-.2 0l-1.5-1.5c-.3-.3-.7-.3-.9 0-.1.2-.2.3-.2.5v4.3c0 .4.3.7.7.7z"});

    FE.RegisterCommand("videoLightbox", {
        title: "Åbn i lightbox",

        // This command doesn't change anything - simply opens a lightbox
        // overlay - so don't push anything to undo stack
        focus: false,
        undo: false,
        refreshAfterCallback: false,

        callback: function () {
            var video = this.video.get().find('iframe, embed, video'),
                videoDomain = (video.attr('src').split('//')[1] || video.attr('src')).split('/')[0],
                videoEmbed = this.video.get().html(),
                videoId = new Date().getTime() + '-' + Math.round(Math.random() * 10000);

            this.lightbox.open({
                'size': {
                    'width' : parseInt(video.attr('width') || video.css('width'), 10) * 2,
                    'height': parseInt(video.attr('height') || video.css('height'), 10) * 2
                },

                'title'     : String(videoDomain).toLowerCase(),
                'contents'  : '<div id="gfr-videoLightbox-' + videoId + '" style="position:relative; width:100%; height:100%; background:#f5f5f5; color:#383838;"><i class="fa fa-spinner fa-pulse fa-3x" style="position:absolute; top:50%; left:50%; margin-top:-21px; margin-left:-21px;"></i></div>'
            });

            setTimeout(function () {
                $('#gfr-videoLightbox-' + videoId).html(videoEmbed);
            }, 900);

            this.video.exitEdit(true, true);
        }
    });
}(jQuery));

import { gEditor } from "js/typings/editor";

import * as css from "../../../../../styles/geogebra";

import { GGBEditorApi, loadGeogebraScript } from "./loadGeogebraScript";
import { processSvg } from "./processSvg";

export function openGeogebraEvaluator(
  editor: gEditor,
  formula?: HTMLImageElement
): void {
  editor.selection.save();

  const lightbox = document.createElement("div");
  lightbox.className = css.backdropCls;

  lightbox.innerHTML = `
    <div class="${css.containerCls}">
      <div class="${css.throbberCls}"></div>
      <div class="${css.inputCls}"></div>
      <div class="${css.toolbarCls}">
        <button data-btn="save"><span>Gem</span></button>
        <button data-btn="cancel"><span>Annuller</span></button>
      </div>
    </div>
  `;

  document.body.appendChild(lightbox);

  // begin fade-in of the lightbox
  lightbox.offsetHeight;
  lightbox.classList.add("enter");

  lightbox
    .querySelector(`[data-btn="cancel"]`)
    ?.addEventListener("click", () => {
      // fade the lightbox out of view
      lightbox.classList.add("exit");

      editor.events.focus();
      editor.selection.restore();

      // completely remove from the DOM once faded out
      setTimeout(() => {
        lightbox.remove();
      }, 125);
    });

  injectApplet(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    lightbox.querySelector<HTMLDivElement>(`.${css.inputCls}`)!
  ).then((api) => {
    const latex = formula?.getAttribute("data-latex");
    const content = formula?.getAttribute("data-content");

    if (latex && content) {
      api.evalLaTeX(latex);
      api.setEditorState({ content });
    } else {
      api.evalLaTeX("");
    }

    api.openKeyboard();

    // display the input now that we know Geogebra is ready
    lightbox.classList.add("ready");

    // remove throbber after fade-in has ended
    setTimeout(() => {
      lightbox.querySelector(`.${css.throbberCls}`)?.remove();
    }, 150);

    // retain open keyboard when clicking anywhere inside the lightbox
    lightbox.addEventListener("mousedown", (evt) => {
      evt.preventDefault();
    });
    lightbox
      .querySelector(`.${css.inputCls}`)
      ?.addEventListener("click", (evt) => {
        evt.stopPropagation();
      });

    // bind listener to cancel (ie. close the lightbox without applying
    // changes)
    lightbox
      .querySelector(`[data-btn="cancel"]`)
      ?.addEventListener("click", () => {
        api.closeKeyboard();
      });

    // bind listener to insert the formula into the Froala editor based on the
    // currently entered one
    lightbox
      .querySelector(`[data-btn="save"]`)
      ?.addEventListener("click", () => {
        api.exportImage({ type: "svg", transparent: true }, (image) => {
          const svg = processSvg(image.svg);
          const { latex, content } = api.getEditorState();

          editor.selection.restore();
          editor.events.focus();

          // insert the image as an svg with dynamically scaled height
          if (!formula) {
            editor.html.insert(
              `<img
                class="LaTeX-formula"
                alt=""
                src="${svg.dataUri}"
                data-latex="${latex.replace(/\"/g, "&#34;")}"
                data-content="${content.replace(/\"/g, "&#34;")}"
                style="max-height: ${1.4 * svg.lineCount}em;"
              />`,
              true
            );
          } else {
            formula.src = svg.dataUri;
            formula.style.maxHeight = `${1.4 * svg.lineCount}em`;
            formula.setAttribute("data-latex", latex);
            formula.setAttribute("data-content", content);

            // put the cursor right after this formula
            editor.selection.restore();
            editor.selection.setAfter(formula);
          }

          // auto-position image resizer
          if (editor.image.get()) {
            editor.image.repositionResizer();
          }

          // emit commands.after event, to allow integrations to intercept the
          // change made
          editor.events.trigger("commands.after", [], true);

          // save current cursor position so it's retained after closing the
          // lightbox
          editor.selection.save();

          // trigger close click event
          lightbox
            .querySelector<HTMLDivElement>(`[data-btn="cancel"]`)
            ?.click();
        });
      });
  });
}

/**
 * due to bugs inside applet injection with a cache to ensure that we only ever
 * create one instance and then re-use it subsequently
 */
function injectApplet(container: HTMLElement): Promise<GGBEditorApi> {
  return new Promise(async (resolve, reject) => {
    try {
      const editor = await loadGeogebraScript();

      // ... otherwise initialize the first version of the editor
      const id = "ggbEditor";

      const node = document.createElement("div");
      node.id = id;
      node.style.width = "100%";
      node.style.height = "100%";

      node.setAttribute("data-param-keyboardType", "scientific");
      node.setAttribute("data-param-fontsize", "32");
      node.setAttribute("data-param-editorForegroundColor", "#090b19");
      node.setAttribute("data-param-editorBackgroundColor", "#fff");
      node.setAttribute("data-param-textmode", "false");

      container.appendChild(node);

      editor
        .create(node)
        .getAPI()
        .then(
          (api) => {
            resolve(api);
          },
          (err) => {
            reject(err);
          }
        );
    } catch (err) {
      reject(err);
    }
  });
}

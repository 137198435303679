import * as FE from "froala-editor";

FE.MODULES.lightbox = function lightbox(editor) {
    var animationSupported  =
        ("animation" in document.body.style) ||
        ("webkitAnimation" in document.body.style) ||
        ("mozAnimation" in document.body.style) ||
        ("msAnimation" in document.body.style) ||
        ("oAnimation" in document.body.style);

    function openLightbox(args) {
        // Create container for the lightbox
        var lightbox = document.createElement("div");

        lightbox.className="gfr-lightbox";
        lightbox.innerHTML = (
            '<div class="gfr-lightbox-blocker"></div>' +
            '<div class="gfr-lightbox-wrapper">' +
                '<header class="gfr-lightbox-header">' +
                    '<div class="gfr-lightbox-header-title">' +
                        String(args.title).replace(/\</g, '&lt;') +
                    '</div>' +
                    '<a href="javascript:;" class="gfr-lightbox-close fa fa-close"></a>' +
                '</header>' +
                '<div class="gfr-lightbox-container">' +
                    args.contents +
                '</div>' +
            '</div>'
        );

        // Callback that'll remove the lightbox from view once it's no longer
        // needed
        var closeLightbox = function () {
            if (animationSupported) {
                lightbox.classList.add("gfr-lightbox-fadeOut");

                setTimeout(function () {
                    document.body.removeChild(lightbox);
                }, 650);
            } else {
                document.body.removeChild(lightbox);
            }

            window.removeEventListener("resize", onResize);
            window.removeEventListener("keydown", onKeyDown);
        }

        // Callback that'll resize and center the lightbox within the bounds of
        // the viewport
        var resizeLightbox = function () {
            var viewport = {
                width: window.innerWidth - 60,
                height: window.innerHeight - 60,
            };

            if (viewport.width / args.size.width < (viewport.height - 55) / args.size.height) {
                var width = Math.min(viewport.width, args.size.width),
                    height = width / args.size.width * args.size.height;
            } else {
                var height = Math.min(viewport.height - 55, args.size.height),
                    width = height / args.size.height * args.size.width;
            }

            // Specify sizing of the container
            var container = lightbox.querySelector(".gfr-lightbox-container");

            container.style.width = width + "px";
            container.style.height = height + "px";

            // Now make sure that the lightbox is centered within the viewport
            var wrapper = lightbox.querySelector(".gfr-lightbox-wrapper");

            wrapper.style.top =Math.round((viewport.height - height - 55) / 2 + 30) + "px";
            wrapper.style.left = Math.round((viewport.width - width) / 2 + 30) + "px";
        };

        // Subscribe resize listener to make sure lightbox is constantly kept in
        // place
        var isResizePending = false;

        var onResize = function () {
            if (isResizePending) {
                return;
            }

            isResizePending = true;

            requestAnimationFrame(function () {
                resizeLightbox();
                isResizePending = false;
            });
        };

        window.addEventListener("resize", onResize);

        // Allow the user to close the lightbox using the escape key
        var onKeyDown = function (evt) {
            if (evt.key === "Escape") {
                closeLightbox();

                evt.stopPropagation();
                evt.preventDefault();
            }
        };

        window.addEventListener("keydown", onKeyDown);

        // Bind event-listeners that will close the lightbox when the user
        // clicks outside of it or on the close-button
        lightbox.querySelector(".gfr-lightbox-blocker").addEventListener("click", closeLightbox);
        lightbox.querySelector(".gfr-lightbox-close").addEventListener("click", closeLightbox);

        // Render lightbox initially
        resizeLightbox();

        document.body.appendChild(lightbox);
    }

    return {
        'open': openLightbox
    };
};
